import { SxProps } from '@mui/material';

type PureSx<Theme extends object> = Exclude<
  SxProps<Theme>,
  ReadonlyArray<unknown>
>;
type SxArray<Theme extends object> = Array<PureSx<Theme>>;

export const mergeSx = <Theme extends object>(
  ...styles: (SxProps<Theme> | false | undefined)[]
): SxProps<Theme> => {
  const combined: SxArray<Theme> = [];
  for (const sx of styles) {
    if (sx) {
      if (Array.isArray(sx)) {
        for (const sub of sx as SxArray<Theme>) {
          combined.push(sub);
        }
      } else {
        combined.push(sx as PureSx<Theme>);
      }
    }
  }
  return combined;
};
