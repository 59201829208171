import React, { useDebugValue, useEffect, useMemo, useState } from 'react';

import { ObservableStorage } from '../functions';

export const useLocalStorage = <S>(
  key: string,
  initialState?: S | (() => S),
): [S, React.Dispatch<React.SetStateAction<S>>] => {
  const [state, setState] = useState<S>(initialState as S);
  useDebugValue(state);

  useEffect(() => {
    const item = localStorage.getItem(key);
    if (item) setState(parse(item));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return [state, setState];
};

const parse = (value: string) => {
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
};

export const useObservableLocalStorage = <S>(
  key: string,
): [S | null, (val: S | null) => void] => {
  const instance = useMemo(
    () => ObservableStorage.getInstance<S | null>(key),
    [key],
  );

  const [state, setState] = useState<S | null>(null);

  useEffect(() => {
    const removeListener = instance.addListener((_, newData) => {
      setState(newData);
    });

    instance.callListeners(null, instance.get());

    const removeAnotherWindowListener = instance.listenAnotherWindow();

    return () => {
      removeListener();
      removeAnotherWindowListener();
    };
  }, [instance]);

  return [state, instance.set];
};
