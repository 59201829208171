import { FC } from 'react';

import { IconsProps } from '@/components/icons/types';

const ArrowRight: FC<IconsProps> = ({ className, fill }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    color="white"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M11.5858 16L13 17.4142L17.7071 12.7071C17.7093 12.705 17.7114 12.7028 17.7135 12.7007C17.8907 12.5202 18 12.2729 18 12C18 11.7235 17.8877 11.4731 17.7063 11.2921L13 6.58582L11.5858 8.00003L14.5858 11L7 11V13L14.5858 13L11.5858 16Z"
      fill={fill ? fill : 'currentColor'}
    />
  </svg>
);

export default ArrowRight;
