type Listener<TData> = (oldData: TData | null, newData: TData | null) => void;

class ObservableKeyStorage<TData> {
  private key: string;

  private listeners: Listener<TData>[] = [];

  constructor(key: string) {
    this.key = key;

    this.set = this.set.bind(this);
    this.get = this.get.bind(this);
    this.addListener = this.addListener.bind(this);
    this.listenAnotherWindow = this.listenAnotherWindow.bind(this);
    this.callListeners = this.callListeners.bind(this);
  }

  public set(newData: TData) {
    const oldData = this.get();

    const jsonData = JSON.stringify(newData);
    localStorage.setItem(this.key, jsonData);

    this.callListeners(oldData, newData);
  }

  public get() {
    const jsonData = localStorage.getItem(this.key);
    return jsonData ? (JSON.parse(jsonData) as TData) : null;
  }

  public addListener(listener: Listener<TData>) {
    this.listeners.push(listener);

    return () => {
      const index = this.listeners.findIndex((x) => x === listener);
      this.listeners.splice(index, 1);
    };
  }

  public listenAnotherWindow() {
    const callback = (e: StorageEvent) => {
      if (e.key === this.key) {
        const oldData: TData | null = e.oldValue
          ? JSON.parse(e.oldValue)
          : null;
        const newData: TData | null = e.newValue
          ? JSON.parse(e.newValue)
          : null;
        this.callListeners(oldData, newData);
      }
    };

    window.addEventListener('storage', callback);

    return () => {
      window.removeEventListener('storage', callback);
    };
  }

  public callListeners(oldData: TData | null, newData: TData | null) {
    this.listeners.forEach((listener) => {
      listener(oldData, newData);
    });
  }
}

export class ObservableStorage {
  private static instance: ObservableStorage;

  private keyStorages: Record<string, ObservableKeyStorage<unknown>> = {};

  public static getInstance<TData>(key: string): ObservableKeyStorage<TData> {
    if (!ObservableStorage.instance) {
      ObservableStorage.instance = new ObservableStorage();
    }

    if (!ObservableStorage.instance.keyStorages[key]) {
      (ObservableStorage.instance.keyStorages[
        key
      ] as ObservableKeyStorage<TData>) = new ObservableKeyStorage<TData>(key);
    }

    return ObservableStorage.instance.keyStorages[
      key
    ] as ObservableKeyStorage<TData>;
  }
}
